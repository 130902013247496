import { useStateMachine } from "little-state-machine";
import Lottie from "lottie-react-web";

import getBrandingSettings from "pages/Payment/Pay/_hooks/useGetBrandingSettings";

import LoaderBlack from "assets/animations/loader-black.json";
import LoaderWhite from "assets/animations/loader-white.json";

export const LoaderPaymentLink = () => {
  const { state } = useStateMachine();
  const link = state.link;
  const merchant = link?.merchant;

  const { hasCustomBranding, brandColor, tinyBrandColor } =
    getBrandingSettings(merchant);

  const isBrandColorDark = hasCustomBranding && tinyBrandColor.isDark();
  const isBrandColorLight = hasCustomBranding && tinyBrandColor.isLight();

  const handleRenderLoaderColor = () => {
    if (isBrandColorDark) {
      return LoaderWhite;
    }

    if (isBrandColorLight) {
      return LoaderBlack;
    }

    if (!(brandColor && hasCustomBranding)) {
      return LoaderBlack;
    }

    return LoaderBlack;
  };

  const handleRenderBackground = () => {
    if (brandColor && hasCustomBranding) {
      return brandColor;
    }

    // if (!(brandColor && hasCustomBranding)) {
    //   return `url(${BgGradient}) no-repeat fixed center/cover`;
    // }

    return "#ffffff";
  };

  return (
    <>
      <main
        className="flex min-h-100dvh items-center justify-center md:min-h-screen"
        style={{
          background: handleRenderBackground(),
        }}
      >
        <Lottie
          options={{
            animationData: handleRenderLoaderColor(),
            loop: true,
          }}
          width={160}
          height={160}
        />
      </main>
    </>
  );
};

export default LoaderPaymentLink;
